import React from 'react';

const Youtube = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="37.217"
    height="26.052"
    viewBox="0 0 37.217 26.052"
  >
    <path
      d="M1020.478,373.965a8.1,8.1,0,0,0-8.1-8.1H991.364a8.1,8.1,0,0,0-8.1,8.1v9.848a8.1,8.1,0,0,0,8.1,8.1h21.013a8.1,8.1,0,0,0,8.1-8.1Zm-13.257,5.42-8.38,4.6c-.364.2-.693-.066-.693-.481v-9.435c0-.42.337-.682.7-.475l8.438,4.839A.549.549,0,0,1,1007.221,379.385Z"
      transform="translate(-983.262 -365.863)"
      fill="currentColor"
    />
  </svg>
);

export default Youtube;
